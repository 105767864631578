import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class AreaCodeService {
    readAreaCodeQuery(){
        var query = `query{
                        GetAreaCode{
                            areacode
                            areaname
                            coverage
                            created_at
                            last_update
                        }
                    }`;
        return query;
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: NewArea!){
                CreateArea(New: $data){
                    response
                }
            }
        `;  
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation($id: String!, $data: NewArea!){
                UpdateArea(AreaCode: $id, New: $data ){
                    response
                }
            }
        `;  
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`
            mutation( $id: String! ){
                DeleteArea( AreaCode: $id ){
                    response
                }
            }
        `;  
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getAreaQuery(){
        var query = gql`query{
            GetAreaCode{
                areacode
                areaname
                coverage
            }
        }`;
        var areaData = await globalfunc.defaultApolloQueryDefault(query);
        return areaData
    }

}

export default new AreaCodeService();